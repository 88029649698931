//  import 'bootstrap/dist/css/bootstrap.css';
import './less/style.less';
import './favicon.ico';

import { initializeGoogleAnalytics } from './ga';
import { registerServiceWorker } from './service-worker';

const GOOGLE_CONTACT_IFRAME_SRC =
    'https://docs.google.com/forms/d/1Am3P5f1AUUnKs2BvswyZ-HZuOF_UN10Mu6ecQWrhAKs/viewform?embedded=true&ttl=0&hl=iw';

registerServiceWorker();

const initializeContactForm = () => {
    document.getElementById('iframe-contact-form').src = GOOGLE_CONTACT_IFRAME_SRC;
};

document.addEventListener('DOMContentLoaded', () => {
    initializeGoogleAnalytics();

    // Add Google Contact Sheet form
    window.setTimeout(initializeContactForm, 50);
});
