export const registerServiceWorker = () => {
    if (process.env.NODE_ENV !== 'production') {
        return;
    }
    if (!('serviceWorker' in navigator)) {
        return;
    }

    window.addEventListener('load', () => {
        navigator.serviceWorker
            .register('/service-worker.js')
            .then(registration => {
                console.log('SW registered: ', registration);
            })
            .catch(registrationError => {
                console.warn('SW registration failed: ', registrationError);
            });
    });
};
